import { safeJSONStringify } from './stringify';

export class JsonRequest<T> extends Request {
  constructor(input: RequestInfo | URL, init?: RequestInit) {
    super(input, init);
  }
  json(): Promise<T> {
    return super.json();
  }
}

export class JsonResponse<T> extends Response {
  constructor(body: T, init?: ResponseInit) {
    const headers = new Headers(init?.headers);
    headers.append('Content-Type', 'application/json');
    super(safeJSONStringify(body), { ...init, headers });
  }
}

export function getHeaders(
  headers?: Record<string, string>,
  cookies?: Record<string, string>,
): Record<string, string> {
  const Cookie = cookies
    ? Object.entries(cookies).map(([k, v]) => `${k}=${v}`).join(';')
    : '';
  return {
    ...(headers ?? {}),
    Cookie,
  };
}

export function headersToRecord(headers: Headers): Record<string, string> {
  const record: Record<string, string> = {};
  headers.forEach((v, k) => record[k] = v);

  return record;
}

export type HTTPMethod = 'GET' 
| 'POST'
| 'PUT'
| 'PATCH'
| 'DELETE'
| 'OPTIONS'
| 'HEAD'
| 'CONNECT'
| 'TRACE';

export const JSONResponses = {
  Unauthorized() {
    return new JsonResponse(
      {
        success: false,
        error: {
          message: 'Unauthorized'
        }
      },
      { status: 401 }
    );
  },
  
  NotFound() {
    return new JsonResponse(
      {
        success: false,
        error: {
          message: 'Object not found'
        }
      },
      { status: 404 }
    );
  },
  
  MethodNotAllowed(allowed: HTTPMethod[]) { // see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods for more methods
    return new JsonResponse(
      {
        success: false,
        error: {
          message: 'Method Not Allowed'
        }
      },
      {
        status: 405,
        headers: { Allow: allowed.join(', ') },
      }
    );
  },
  
  Forbidden(error?: any) {
    return new JsonResponse(
      {
        success: false,
        error: error ?? {
          message: 'Forbidden'
        }
      },
      { status: 403 }
    );
  },
};

class JsonResponseV2Impl<T> extends Response {
  constructor(body: T, init?: ResponseInit) {
    super();
    return Response.json(body, init);
  }
}

export type JsonResponseV2<T> = typeof JsonResponseV2Impl<T>

export function JsonResponseV2<T>(body: T, init?: ResponseInit) {
  return new JsonResponseV2Impl<T>(body, init) as unknown as JsonResponse<T>;
}
