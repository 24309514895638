import { unaccentLower } from './string';

export interface Config {
  firstNameRequired: boolean;
  lastNameRequired: boolean;
  excludeTerms: string[];
}

const defaultConfig: Config = {
  firstNameRequired: true,
  lastNameRequired: true,
  excludeTerms: ['de', 'da', 'dos', 'das'],
};

/**
 * @param base
 * @param compare
 * @param config
 * @returns a score from 0 to 1. 1 being a perfect match.
 */
export const comparePeopleNames = (
  base: string,
  compare: string,
  config?: Partial<Config>,
): number => {
  const configs: Config = {
    ...defaultConfig,
    ...(!!config && config),
  };
  let matches = 0;

  const [nBase, nCompare] = [unaccentLower(base), unaccentLower(compare)];
  const [cBase, cCompare] = removeTerms(nBase, nCompare, configs.excludeTerms);
  const [sBase, sCompare] = [cBase.split(' '), cCompare.split(' ')];


  if (!configs.lastNameRequired && sBase.length !== sCompare.length) {
    if (sBase.length < sCompare.length) {
      sCompare.pop();
    }else{
      if (sBase.length > sCompare.length) {
        sBase.pop();
      }
    }
  }


  const [rBase, rCompare] = [[...sBase], [...sCompare]];

  
  if (configs.lastNameRequired && (sBase.length < 2 || sCompare.length < 2)) {
    throw new Error('Invalid input names. Must be formed of at least 2 words');
  }

  if (configs.firstNameRequired) {
    if (sBase[0] !== sCompare[0]) return 0;
    rBase.shift();
    rCompare.shift();
    matches += 1;
  }


  if (configs.lastNameRequired) {
    if (sBase[sBase.length - 1] !== sCompare[sCompare.length - 1]) return 0;
    rBase.pop();
    rCompare.pop();
    matches += 1;
  }
  

  matches += rBase.map((n): number => {
    if (rBase.indexOf(n) === rCompare.indexOf(n)) {
      return 1;
    } else {
      return 0;
    }
  }).reduce((v, curr) => curr+v, 0);

  return matches / sBase.length;
};

const removeTerms = (
  base: string,
  compare: string,
  excludeTerms: string[],
): [string, string] => {
  return [
    base.split(' ').filter((e) => !excludeTerms.includes(e)).join(' '),
    compare.split(' ').filter((e) => !excludeTerms.includes(e)).join(' '),
  ];
};

/**
 * @param base
 * @param compare
 * @returns rate
 */
const compare1to1 = (base: string, compare: string): number => {
  const matches = base.split('').reduce((v, curr, i) => {
    if (curr === compare[i]) v++;

    return v;
  }, 0);

  return (matches / base.length);
};


export const shallowComparePersonToCompanyName = (
  personName: string,
  companyName: string,
  minMatch: number,
  excludeTerms = defaultConfig.excludeTerms,
): boolean => {
  
  const [nPerson, nCompany] = [unaccentLower(personName).replace(/([\d]+[\.]*)/g, '').replaceAll('.', '').trim(), unaccentLower(companyName).replace(/([\d]+[\.]*)/g, '').replaceAll('.', '').trim()];
  const [cPerson, cCompany] = removeTerms(nPerson, nCompany, excludeTerms);
  const [sPerson, sCompany] = [cPerson.split(' '), cCompany.split(' ')];

  const pLastName = sPerson.slice(-1)[0];
  const companyIncludeLastName = sCompany.includes(pLastName);

  if (!companyIncludeLastName) return false;

  const companyBeforeLast = sCompany.slice(0, sCompany.indexOf(pLastName));
  if (!companyBeforeLast.length) return false;

  const personBeforeLast = sPerson.slice(0, -1);
  const resultSum = companyBeforeLast.reduce((v, name, i) => {
    if (!personBeforeLast[i]) return v;
    if (personBeforeLast[i] === name || personBeforeLast[i][0] === name) v++;
    return v;
  }, 0);

  return (resultSum / companyBeforeLast.length) >= minMatch;
};
