/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BacgkgroundCheckSummary = {
  properties: {
    mensagens: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    resultado: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    validado_em: {
      type: 'string',
    },
    cnpj: {
      type: 'string',
    },
    processos: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    protestos: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    ofac_sdn: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    divida_ativa: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
  },
} as const;
