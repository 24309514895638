/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FacialRecognitionRequest } from '../models/FacialRecognitionRequest';
import type { GetAvailableCompanyDocumentsResponse } from '../models/GetAvailableCompanyDocumentsResponse';
import type { GetAvailableProfessionDocumentsResponse } from '../models/GetAvailableProfessionDocumentsResponse';
import type { GetUserWorkflowStepsRequest } from '../models/GetUserWorkflowStepsRequest';
import type { GetUserWorkflowStepsResponse } from '../models/GetUserWorkflowStepsResponse';
import type { ListBanksResponse } from '../models/ListBanksResponse';
import type { Response } from '../models/Response';
import type { SendProfessionalDocumentsRequest } from '../models/SendProfessionalDocumentsRequest';
import type { SendProfessionalDocumentsResponse } from '../models/SendProfessionalDocumentsResponse';
import type { SetDigitalCertificateRequest } from '../models/SetDigitalCertificateRequest';
import type { UserComplianceRequest } from '../models/UserComplianceRequest';
import type { UserComplianceResponse } from '../models/UserComplianceResponse';
import type { UserResponse } from '../models/UserResponse';
import type { UserSigninInitRequest } from '../models/UserSigninInitRequest';
import type { UserSignInResponse } from '../models/UserSignInResponse';
import type { UserSigninVerifyRequest } from '../models/UserSigninVerifyRequest';
import type { Workflow } from '../models/Workflow';
import type { WorkflowStepId } from '../models/WorkflowStepId';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get the logged-in user
   * @returns UserResponse The logged-in user
   * @throws ApiError
   */
  public meUser(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me',
    });
  }

  /**
   * Sign-in or create a user with a valid email or phone
   * @param requestBody
   * @returns Response Returns if the request was either successfully or not
   * @throws ApiError
   */
  public siginUser(
    requestBody: UserSigninInitRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/signin',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Sign-in or create a user with a valid email or phone
   * @param requestBody
   * @returns UserSignInResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public siginVerifyUser(
    requestBody: UserSigninVerifyRequest,
  ): CancelablePromise<UserSignInResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/signinVerify',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Answer to the compliance questions
   * @param requestBody
   * @returns UserComplianceResponse Returns if the request was sucessful
   * @throws ApiError
   */
  public complianceQuestions(
    requestBody: UserComplianceRequest,
  ): CancelablePromise<UserComplianceResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/complianceQuestions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list of all available banks
   * @returns ListBanksResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public listBanks(): CancelablePromise<ListBanksResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/listBanks',
    });
  }

  /**
   * Notify doctor onboarding api that a user completed its facial recognition
   * @param requestBody
   * @returns Response Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public facialRecognitionCompleted(
    requestBody: FacialRecognitionRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/facialRecognitionCompleted',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Receive the person's digital certifications choice
   * @returns GetAvailableProfessionDocumentsResponse Returns success/APIError
   * @throws ApiError
   */
  public getProfessionAvailableDocuments(): CancelablePromise<GetAvailableProfessionDocumentsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/get-available-documents',
    });
  }

  /**
   * Receive the person's digital certifications choice
   * @returns GetAvailableCompanyDocumentsResponse Returns success/APIError
   * @throws ApiError
   */
  public getCompanyAvailableDocuments(): CancelablePromise<GetAvailableCompanyDocumentsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/get-available-documents',
    });
  }

  /**
   * Receive the person's professional document for validation
   * @param requestBody
   * @returns SendProfessionalDocumentsResponse Returns success/APIError
   * @throws ApiError
   */
  public setProfessionalDocument(
    requestBody: SendProfessionalDocumentsRequest,
  ): CancelablePromise<SendProfessionalDocumentsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/set-documents',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Receive the person's digital certifications choice
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public setDigitalCertificate(
    requestBody: SetDigitalCertificateRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/set-digital-certificate',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get the steps for a given user workflow
   * @param requestBody
   * @returns GetUserWorkflowStepsResponse Returns success/APIError
   * @throws ApiError
   */
  public getUserWorkflow(
    requestBody: GetUserWorkflowStepsRequest,
  ): CancelablePromise<GetUserWorkflowStepsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/workflow/getSteps',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get all the steps for a given workflow
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public getUserWorkflowNextStep(
    requestBody: {
      requestedStepId: WorkflowStepId;
      workflowId: Workflow;
    },
  ): CancelablePromise<(Response & {
    data: {
      workflowStepId?: WorkflowStepId;
      toStatus?: boolean;
    };
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/workflow/getNextStep',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
