/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalInfo = {
  properties: {
    profession: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'string',
      isRequired: true,
    },
    register: {
      type: 'ProfessionalRegister',
      isRequired: true,
    },
    documents: {
      type: 'array',
      contains: {
        type: 'ProfessionalDocument',
      },
    },
    extraSpecialties: {
      type: 'array',
      contains: {
        type: 'DoctorExtraSpecialty',
      },
    },
    digitalCertificate: {
      type: 'ProfessionalDigitalCertificate',
    },
  },
} as const;
