/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Report = {
  properties: {
    id: {
      type: 'number',
    },
    user_id: {
      type: 'string',
    },
    data_type: {
      type: 'string',
    },
    external_id: {
      type: 'string',
    },
    external_url: {
      type: 'string',
    },
    matrix: {
      type: 'string',
    },
    params: {
      type: 'string',
    },
    status_text: {
      type: 'string',
    },
    result: {
      properties: {
      },
    },
  },
} as const;
