/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $IdwallWebhookRequest = {
  properties: {
    tipo: {
      type: 'string',
    },
    dados: {
      properties: {
        protocolo: {
          type: 'string',
        },
        status: {
          type: 'string',
        },
      },
    },
  },
} as const;
