/* eslint-disable @typescript-eslint/no-explicit-any */
const FUNC_ERROR_TEXT = 'Expected a function';


export interface MemoizeOpts<T extends (...args: any[]) => any> {
  cache?: Map<any, any>;
  cacheKeyResolver?: (...args: Parameters<T>) => any;
  /**
   * TTL in miliseconds(ms)
   */
  ttl?: number;
}

/**
 * Creates a memo of the given function. 
 * @param func your function
 * @param options allows for defining custom memo
 * @returns a function that handles memo and executes your func
 */
export function memoize<T extends (...args: any[]) => any>(
  func: T,
  options?: MemoizeOpts<T>,
): T {
  if (typeof func !== 'function') {
    throw new TypeError(FUNC_ERROR_TEXT);
  }

  let cache = options?.cache ?? new Map();
  const cacheKeyResolver = options?.cacheKeyResolver;
  const ttl = options?.ttl;

  return ((...args: any[]) => {
    const key = cacheKeyResolver
      ? cacheKeyResolver(...(args as Parameters<T>))
      : func;

    if (ttl) {
      if (cache.has(key)) {
        const [value, expiresAt] = cache.get(key);
        if (expiresAt > Date.now()) {
          return value;
        } else {
          cache.delete(key);
        }
      }
      const result = func(...args);
      cache = cache.set(key, [result, Date.now() + ttl]) ?? cache;
      return result;
    } else {
      if (cache.has(key)) {
        return cache.get(key);
      }
      const result = func(...args);
      cache = cache.set(key, result) ?? cache;
      return result;
    }
  }) as T;
}
