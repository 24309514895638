/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataEndereco = {
  properties: {
    cep: {
      type: 'string',
    },
    pais: {
      type: 'string',
    },
    tipo: {
      type: 'string',
    },
    bairro: {
      type: 'string',
    },
    cidade: {
      type: 'string',
    },
    estado: {
      type: 'string',
    },
    numero: {
      type: 'string',
    },
    endereco: {
      type: 'string',
    },
    latitude: {
      type: 'string',
    },
    longitude: {
      type: 'string',
    },
    complemento: {
      type: 'string',
    },
    enderecoPrincipal: {
      type: 'boolean',
    },
  },
} as const;
