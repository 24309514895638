/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalContract = {
  properties: {
    professionalId: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    cnpj: {
      type: 'string',
      isRequired: true,
    },
    companyMainName: {
      type: 'string',
      isRequired: true,
    },
    crm: {
      type: 'string',
      isRequired: true,
    },
    crmUF: {
      type: 'string',
      isRequired: true,
    },
    providerId: {
      type: 'string',
      isRequired: true,
    },
    contracts: {
      type: 'array',
      contains: {
        properties: {
          id: {
            type: 'string',
          },
          templateName: {
            type: 'string',
          },
          status: {
            type: 'ContractStatus',
          },
          hirers: {
            type: 'array',
            contains: {
              type: 'HirerBasicInfo',
            },
          },
        },
      },
      isRequired: true,
    },
  },
} as const;
