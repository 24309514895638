import { memoize } from './memo';

export const fetchWithTimeoutBuilder = memoize(
  (timeout: number) => (input: RequestInfo, init?: RequestInit) =>
    fetchWithTimeout(
      timeout,
      input,
      init,
    ),
  {
    cacheKeyResolver: (timeout: number) => timeout,
  },
);

export async function fetchWithTimeout(
  timeout: number,
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> {
  const controller = new AbortController();
  const signal = controller.signal;
  const racePromise = new Promise((
    _,
    reject,
  ) =>
    setTimeout(() => {
      controller.abort();
      reject(new Error('Request timed out'));
    }, timeout)
  );
  return await Promise.race([
    fetch(input, { ...init, signal }),
    racePromise,
  ]) as Promise<Response>;
}
