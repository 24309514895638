/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DoctorExtraSpecialty = {
  properties: {
    id: {
      type: 'string',
    },
    specialty: {
      type: 'string',
      isRequired: true,
    },
    type: {
      type: 'DoctorSpecialtyType',
      isRequired: true,
    },
    status: {
      type: 'DoctorExtraSpecialtyStatus',
      isRequired: true,
    },
    file: {
      type: 'File',
      isRequired: true,
    },
    toDelete: {
      type: 'boolean',
    },
  },
} as const;
