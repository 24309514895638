export function removeSpecialCharacters(str?: string): string {
  return str
    ? str.replace(/[^a-zA-Z0-9 ]/g, '')
    : '';
}

export function unaccent(str?: string): string {
  return str
    ? str.trim().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
    : '';
}

export function unaccentLower(str?: string): string {
  return str ? unaccent(str.toLocaleLowerCase()) : '';
}

export function unaccentUpper(str?: string): string {
  return str ? unaccent(str.toLocaleUpperCase()) : '';
}

export function capitalize(str: string): string {
  return str.trim().split(' ').map(s => `${s[0]?.toLocaleUpperCase()}${s.slice(1).toLocaleLowerCase()}`).join(' ');
}

export function truncate(str: string, max = 150, end = '...'): string {
  if (str.length > max) {
    return str.slice(0, max) + end;
  } else {
    return str;
  }
}

/**
 * Removes left zeros from string
 * @param str 
 * @returns the new string or the given string if it is all zeros(0)
 */
export function removeLeftZeros(str: string): string {
  const reg = /^0+/g;
  const res = str.replace(reg, '');
  
  if (str !== '' && res === '') {
    return str;
  }

  return res;
}

export function toBoolean(str: string): boolean {
  return str?.toLowerCase() === 'true';
}

export function toArray(str: string): string[] {
  return str.split(',').map((s) => s.trim());
}

export function reverseString(str: string) {
  return str.split('').reverse().join('');
}

/**
 * Create a string function comparator
 * @param a String `a` to be compared with
 * @returns Returns a function that compares strings against a given string
 */
export function strEqIgnoreCase(a: string) {
  return (b: string) => a.toLowerCase() === b.toLowerCase();
}
