import { type Either } from 'fp-ts/Either';
import { JsonResponse } from './http';

export interface ApiOkResponseData<T> {
  success: true;
  data: T;
}

export interface ApiErrorData<D = never> {
  code: number;
  name: string;
  message: string;
  details?: D;
}

export interface ApiErrorResponseData<D = never> {
  success: false;
  error: ApiErrorData<D>;
}

export type ApiResult<T = undefined, D = never> =
  Either<ApiErrorData<D>, T>;

export type ApiClientResult<T, D> =
  Either<ApiErrorResponseData<D>, ApiOkResponseData<T>>;

export class OkJsonResponse<T>
  extends JsonResponse<ApiOkResponseData<T>> {
  constructor(data: T, init?: ResponseInit) {
    super({ success: true, ...(data && { data }) }, init);
  }
}

export const Ok = <T>(data?: T) =>
  new OkJsonResponse(data);

export class ErrorJsonResponse<D = never>
  extends JsonResponse<ApiErrorResponseData<D>> {
  constructor(error: ApiErrorData<D>, init?: ResponseInit) {
    super({ success: false, error }, { status: error.code, ...init });
  }
}

export class ApiErrorObj<D = never>
  extends Error implements ApiErrorData<D> {
  code: number;
  name: string;
  message: string;
  details?: D;

  constructor({
    code,
    name,
    message,
    details,
  }: ApiErrorData<D>) {
    super(message);
    this.code = code;
    this.name = name;
    this.message = message;
    this.details = details;
  }

  toJSON(): ApiErrorData<D> {
    return {
      code: this.code,
      name: this.name,
      message: this.message,
      details: this.details,
    };
  }
}

export const ApiError = <D = never>(
  errorData: ApiErrorData<D>,
  message?: string,
): ApiErrorData<D> => {
  const error = new ApiErrorObj<D>({
    ...errorData,
    message: message ?? errorData.message,
  });
  // captureStackTrace is not a standard property
  // @ts-ignore
  Error.captureStackTrace?.(error);
  return error;
};

export const ResponseError = <D = never>(
  errorData: ApiErrorData<D>,
  message?: string,
) => new ErrorJsonResponse(
    ApiError<D>(errorData, message)
  );

const Errors = {
  InternalServerError: {
    code: 500,
    name: 'InternalServerError',
    message: 'Internal server error',
  },
  BadRequest: {
    code: 400,
    name: 'BadRequest',
    message: 'Bad request',
  },
  NotAuthenticated: {
    code: 401,
    name: 'NotAuthenticated',
    message: 'Not authenticated',
  },
  NotFound: {
    code: 404,
    name: 'NotFound',
    message: 'Not found',
  },
} as const;

export const InternalServerError = () =>
  ResponseError(Errors.InternalServerError);

export const BadRequest = (message?: string) =>
  ResponseError(Errors.BadRequest, message);

export const NotAuthenticated = (message?: string) =>
  ResponseError(Errors.NotAuthenticated, message);

export const NotFound = (message?: string) =>
  ResponseError(Errors.NotFound, message);
