/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FacematchSummary = {
  properties: {
    mensagens: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    resultado: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    validado_em: {
      type: 'string',
    },
    nome_similaridade: {
      type: 'number',
    },
    biom_similaridade: {
      type: 'number',
    },
    biom_probabilidade: {
      type: 'string',
    },
    fm_confidence: {
      type: 'number',
    },
  },
} as const;
