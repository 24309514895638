/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Company = {
  properties: {
    cnpj: {
      type: 'string',
      isRequired: true,
    },
    mainName: {
      type: 'string',
    },
    publicName: {
      type: 'string',
    },
    registrationStatus: {
      type: 'string',
    },
    establishmentType: {
      type: 'string',
    },
    juridicNature: {
      type: 'JuridicNature',
    },
    jurisdictionCity: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    phones: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    mainCNAE: {
      type: 'CNAE',
    },
    secondaryCNAEs: {
      type: 'array',
      contains: {
        type: 'CNAE',
      },
    },
    members: {
      type: 'array',
      contains: {
        type: 'CompanyMember',
      },
    },
    shareCapital: {
      type: 'number',
    },
    size: {
      type: 'string',
    },
    specialSituation: {
      type: 'string',
    },
    specialSituationDate: {
      type: 'number',
    },
    isSimples: {
      type: 'boolean',
    },
    simplesInfo: {
      type: 'CompanySimplesInfo',
    },
    isMei: {
      type: 'string',
    },
    openingDate: {
      type: 'number',
    },
    citySubscriptionNumber: {
      type: 'string',
    },
    stateSubscriptionNumber: {
      type: 'string',
    },
    address: {
      type: 'Address',
    },
    documents: {
      type: 'array',
      contains: {
        type: 'CompanyDocument',
      },
    },
    bankAccount: {
      type: 'CompanyBankAccount',
    },
  },
} as const;
