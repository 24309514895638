/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReportSummaryResponse = {
  properties: {
    images: {
      type: 'ImageSummary',
    },
    facematch: {
      type: 'FacematchSummary',
    },
    ocr: {
      type: 'OcrSummary',
    },
    background_check: {
      type: 'BacgkgroundCheckSummary',
    },
    cpf_associates: {
      type: 'CPFAssociatesSummary',
    },
    cnpj_associates: {
      type: 'CNPJAssociatesSummary',
    },
  },
} as const;
