/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ContractAssignmentHistoryEntry = {
  properties: {
    transactionId: {
      type: 'number',
    },
    time: {
      type: 'string',
      format: 'date-time',
    },
    statusBefore: {
      type: 'ReviewStatus',
    },
    statusAfter: {
      type: 'ReviewStatus',
    },
    reviewerName: {
      type: 'string',
    },
    action: {
      type: 'HistoryAction',
    },
    justification: {
      type: 'string',
    },
    reviewerType: {
      type: 'ContractReviewerType',
    },
    order: {
      type: 'string',
    },
  },
} as const;
