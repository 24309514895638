/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OcrSummary = {
  properties: {
    document: {
      type: 'string',
    },
    data_emissao: {
      type: 'string',
    },
    validade: {
      type: 'string',
    },
    cpf: {
      type: 'string',
    },
    rg: {
      type: 'string',
    },
    orgao_emissor: {
      type: 'string',
    },
    estado_emissor: {
      type: 'string',
    },
    nome: {
      type: 'string',
    },
    data_nascimento: {
      type: 'string',
    },
    nome_mae: {
      type: 'string',
    },
    nome_pai: {
      type: 'string',
    },
    document_files: {
      properties: {
        front: {
          type: 'string',
          isRequired: true,
        },
        back: {
          type: 'string',
          isRequired: true,
        },
        full: {
          type: 'string',
          isRequired: true,
        },
      },
    },
  },
} as const;
