export const parseJson = (json: any) => JSON.parse(json);

export { default as merge } from 'ts-deepmerge';

export * from './api';
export * from './bank';
export * from './builder';
export * from './cnpj';
export * from './concurrency';
export * from './cookie';
export * from './crypto';
export * from './date';
export * from './doctor';
export * from './enum';
export * from './fetch';
export * from './fetch_safe';
export * from './file';
export * from './format_bytes';
export * from './http';
export * from './io';
export * from './jwt';
export * from './memo';
export * from './misc';
export * from './name_matcher';
export * from './queue';
export * from './string';
export * from './stringify';
export * from './type';
export * from './validators';
export * from './brazil';
export * from './phone';
export * from './functional';
export * from './hono';
