/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundCheckRequest } from '../models/BackgroundCheckRequest';
import type { GetReportRequest } from '../models/GetReportRequest';
import type { GetReportResponse } from '../models/GetReportResponse';
import type { IdwallWebhookRequest } from '../models/IdwallWebhookRequest';
import type { ReportDocumentDataRequest } from '../models/ReportDocumentDataRequest';
import type { ReportDocumentDataResponse } from '../models/ReportDocumentDataResponse';
import type { ReportSummaryRequest } from '../models/ReportSummaryRequest';
import type { ReportSummaryResponse } from '../models/ReportSummaryResponse';
import type { Response } from '../models/Response';
import type { SubmitBiometricsRequest } from '../models/SubmitBiometricsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Submits the idwall webSDK biometrics token
   * @param requestBody
   * @returns Response Returns success/error
   * @throws ApiError
   */
  public submitBiometrics(
    requestBody: SubmitBiometricsRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/report/submitBiometrics',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Queries idwall for the person's / company's background check
   * @param requestBody
   * @returns Response Returns success/error
   * @throws ApiError
   */
  public backgroundCheck(
    requestBody: BackgroundCheckRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/report/backgroundCheck',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get an existing report.
   * @param requestBody
   * @returns GetReportResponse Returns the report data
   * @throws ApiError
   */
  public getReport(
    requestBody: GetReportRequest,
  ): CancelablePromise<GetReportResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/report/q',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Load report data and return a summary.
   * @param requestBody
   * @returns ReportSummaryResponse Returns the report data
   * @throws ApiError
   */
  public reportSummary(
    requestBody: ReportSummaryRequest,
  ): CancelablePromise<ReportSummaryResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/report/summary',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Load report sent documents data.
   * @param requestBody
   * @returns ReportDocumentDataResponse Returns the report data
   * @throws ApiError
   */
  public getDocumentData(
    requestBody: ReportDocumentDataRequest,
  ): CancelablePromise<ReportDocumentDataResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/report/document-data',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Idwall report webhook.
   * @param requestBody
   * @returns Response success
   * @throws ApiError
   */
  public reportWebhook(
    requestBody: IdwallWebhookRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/report/webhook',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Internal Server Error`,
      },
    });
  }

}
