/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetReportResponse = {
  properties: {
    data: {
      type: 'array',
      contains: {
        type: 'Report',
      },
    },
  },
} as const;
