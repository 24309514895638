/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImageSummary = {
  properties: {
    selfie: {
      type: 'string',
    },
    documents: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    liveness: {
      type: 'string',
    },
  },
} as const;
