import * as jose from 'jose';
import { type JWTVerifyOptions } from 'jose';
import * as E from 'fp-ts/lib/Either';
import * as F from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';

import { isAsNotEmptyRecord } from './type';

const ERR_JWKS_MULTIPLE_MATCHING_KEYS = 'ERR_JWKS_MULTIPLE_MATCHING_KEYS';
const ERR_JWS_SIGNATURE_VERIFICATION_FAILED =
  'ERR_JWS_SIGNATURE_VERIFICATION_FAILED';

export interface MakeJwtRemoteValidatorParams {
  url: URL | string;
  issuer?: string;
  audience?: string;
  options?: JWTVerifyOptions;
}
export function makeJwtRemoteValidator({
  url,
  issuer,
  audience,
  options,
}: MakeJwtRemoteValidatorParams): (
  jwt: string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<E.Either<Error, any>> {
  const JWKS = jose.createRemoteJWKSet(url instanceof URL ? url : new URL(url));

  return function validateJwt(jwt: string) {
    const verify = () =>
      jose
        .jwtVerify(jwt, JWKS, {
          issuer,
          audience,
        })
        .catch(async (error) => {
          if (error?.code === ERR_JWKS_MULTIPLE_MATCHING_KEYS) {
            for await (const publicKey of error) {
              try {
                return await jose.jwtVerify(jwt, publicKey, options);
              } catch (innerError: unknown) {
                if (
                  isAsNotEmptyRecord(innerError) &&
                  innerError?.code === ERR_JWS_SIGNATURE_VERIFICATION_FAILED
                ) {
                  continue;
                }

                throw innerError;
              }
            }
            throw new jose.errors.JWSSignatureVerificationFailed();
          }
          throw error;
        });

    return F.pipe(TE.tryCatch(verify, E.toError))();
  };
}

export type JWTVerifyResult = jose.JWTVerifyResult;

export function getJWTValues(jwt: string) {
  if (!jwt) return null;
  function parseJwt (token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  return parseJwt(jwt);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function signJWTHS256(payload: Record<string, any>, issuer: string, audience: string, secret: string, expiration: string) {
  const secretE = new TextEncoder().encode(secret);
  const alg = 'HS256';
  
  const jwt = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer(issuer)
    .setAudience(audience)
    .setExpirationTime(expiration)
    .sign(secretE);

  return jwt;
}

export async function verifyJWT(jwt: string, secret: string, options?: JWTVerifyOptions) {
  const secretE = new TextEncoder().encode(secret);
  return await jose.jwtVerify(jwt, secretE, options);
}
