/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReportDocumentData = {
  properties: {
    document_type: {
      type: 'DocumentType',
    },
    filename_front: {
      type: 'string',
    },
    filename_back: {
      type: 'string',
    },
    filename_full: {
      type: 'string',
    },
    result: {
      type: 'ParamsResult',
    },
  },
} as const;
