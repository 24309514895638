/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CNPJAssociatesSummary = {
  properties: {
    mensagens: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    resultado: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    validado_em: {
      type: 'string',
    },
    cnpj: {
      type: 'string',
    },
    nome_fantasia: {
      type: 'string',
    },
    razao_social: {
      type: 'string',
    },
    data_abertura: {
      type: 'string',
    },
    capital_social: {
      type: 'string',
    },
    natureza_juridica: {
      type: 'string',
    },
    regime_tributario: {
      type: 'string',
    },
    porte: {
      type: 'string',
    },
    telefones: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    emails: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    cnaes: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    enderecos: {
      type: 'array',
      contains: {
        type: 'DataEndereco',
      },
    },
    relacionamentos: {
      type: 'array',
      contains: {
        properties: {
          cpf: {
            type: 'string',
          },
          nome: {
            type: 'string',
          },
          tipo_relacionamento: {
            type: 'string',
          },
        },
      },
    },
  },
} as const;
