export const STATES_PREFIX: Record<string, string> = {
  RJ: '52'
} as const;

export const normalizeCRM = (crmStr: string, ufStr: string): string => {
  if (STATES_PREFIX[ufStr]) {
    const len = STATES_PREFIX[ufStr].length;
    return STATES_PREFIX[ufStr] === crmStr.slice(0, len)
      ? crmStr.slice(len)
      : crmStr;
  }
  return crmStr;
};
