/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetReportRequest = {
  properties: {
    user_id: {
      type: 'string',
    },
    external_id: {
      type: 'string',
    },
    matrix: {
      type: 'string',
    },
  },
} as const;
