import { Either, getOrElseW } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { ValidationError } from 'io-ts';
import { failure } from 'io-ts/lib/PathReporter';

export type ReverseMap<T> = Map<{ [K in keyof T]: T[K] }[keyof T], keyof T>;

/* eslint-disable-next-line */
export const optional = (type: t.Type<any>) => {
  const A = t.type({
    type: type,
    value: t.union([type, t.null, t.undefined]),
  });
  const B = t.partial({
    target: t.union([type, t.null, t.undefined]),
  });
  return t.intersection<typeof A, typeof B>([A, B]);
};

/* eslint-disable-next-line */
export function fromEnum<T, E extends object = object>(
  enumeration: E,
  /* eslint-disable-next-line */
  name: string = "enum"
): t.Type<T> {
  return new (class extends t.Type<T> {
    readonly reverseMap: ReverseMap<E>;

    constructor() {
      super(
        name,
        (value: unknown): value is T =>
          this.reverseMap.has(value as E[keyof E]),
        (value: unknown, context: t.Context): Either<t.Errors, T> =>
          this.is(value) ? t.success(value) : t.failure(value, context),
        t.identity
      );

      this.reverseMap = enumReverseMap(enumeration);
    }
  })();
}

/* eslint-disable-next-line */
export function enumReverseMap<E extends object>(
  enumeration: E
): ReverseMap<E> {
  return new Map(
    Object.entries(enumeration).map(([key, value]) => [value, key as keyof E])
  );
}

export const validationErrorsToMessage = (errors: t.Errors) =>
  'Invalid keys: ' +
  errors.map(
    (e: ValidationError) => e.context.map(({ key }) => key).filter(Boolean).join('.')
  ).join(', ');

export const validationErrorsToError = (errors: t.Errors) =>
  new Error(validationErrorsToMessage(errors));

export const getOrThrow = getOrElseW((errors: t.Errors) => {
  throw new Error(failure(errors).join('\n'));
});
