/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FeatureFlags = {
  properties: {
    ENABLE_CONTRACT_REVIEWER: {
      type: 'boolean',
    },
  },
} as const;
