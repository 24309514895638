/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreateContract = {
  properties: {
    professionalId: {
      type: 'string',
      isRequired: true,
    },
    hirers: {
      type: 'array',
      contains: {
        type: 'CreateContractHirerInfo',
      },
      isRequired: true,
    },
    templateId: {
      type: 'string',
      isRequired: true,
    },
    parameters: {
      properties: {
      },
      isRequired: true,
    },
    expiration: {
      type: 'string',
      isRequired: true,
      format: 'date',
    },
    contractReviewers: {
      properties: {
        firstReviewerId: {
          type: 'string',
          isRequired: true,
        },
        secondReviewerId: {
          type: 'string',
          isRequired: true,
        },
        reviewStatus: {
          type: 'ReviewStatus',
        },
      },
    },
    contractCompetencyId: {
      type: 'string',
      isRequired: true,
    },
    elaboratorId: {
      type: 'string',
    },
  },
} as const;
