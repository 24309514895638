/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CPFAssociatesSummary = {
  properties: {
    mensagens: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    resultado: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    validado_em: {
      type: 'string',
    },
    cpf: {
      type: 'string',
    },
    pep: {
      type: 'boolean',
    },
    processos: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    emails: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    telefones: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    participacao_empresas: {
      type: 'array',
      contains: {
        properties: {
          cnpj: {
            type: 'string',
          },
          nome_empresarial: {
            type: 'string',
          },
          tipo_relacionamento: {
            type: 'string',
          },
        },
      },
    },
  },
} as const;
